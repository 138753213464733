import React from 'react';
import { createRoot } from 'react-dom/client';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';

import './index.css';

import { init as initTracking } from 'shared/utils/tracking';
import { config as trackingConfig } from 'shared/utils/trackingConfig';
import {
  isLocalhost,
  isProd,
  REACT_APP_AUTH0_API_AUDIENCE,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_DOMAIN,
} from 'shared';

import { Auth0Provider } from '@auth0/auth0-react';
import { DEBUG_DATA } from 'shared/utils/debug';
import * as serviceWorker from './serviceWorker';

const commit = process.env.CIRCLE_SHA1;
let release = process.env.REACT_APP_VERSION;
if (commit) {
  release += `@${commit}`;
}

if (!isLocalhost()) {
  Sentry.init({
    dsn: trackingConfig.sentryDSN,
    integrations: [new BrowserTracing()],
    release,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
  });
}

initTracking();

// Disable console.log in production
// The condition is not same, check if the app is built by `npm run build` and env is prod
if (process.env.NODE_ENV === 'production' && isProd()) {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  console.log = () => {};
}

if (typeof window !== 'undefined') {
  // eslint-disable-next-line no-underscore-dangle
  window.__DEBUG__ = DEBUG_DATA;
}

import(
  process.env.REACT_APP_BUILD_TARGET === 'client'
    ? './client-web'
    : './agent-web'
).then(({ default: Environment }) =>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
      <Auth0Provider
        useRefreshTokens
        useRefreshTokensFallback
        domain={REACT_APP_AUTH0_DOMAIN}
        clientId={REACT_APP_AUTH0_CLIENT_ID}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: REACT_APP_AUTH0_API_AUDIENCE,
          scope: 'openid profile email',
        }}
      >
        <Environment />
      </Auth0Provider>
    </React.StrictMode>,
  ),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
