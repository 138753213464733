import React from 'react';
import { StyledPopover } from './styledComponents';

type Props = {
  children: JSX.Element;
  content: string;
  trigger?: any; // ActionType export from @rc-components
};

const Popover: React.FC<Props> = ({ content, children, trigger = 'hover' }) => {
  return (
    <StyledPopover content={content} placement="rightTop" trigger={trigger}>
      {children}
    </StyledPopover>
  );
};

export default React.memo(Popover);
