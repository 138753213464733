import { useState, useCallback } from 'react';

const useToggle = (initialState: boolean): [boolean, () => void] => {
  const [value, setValue] = useState(initialState ?? true);
  const toggle = useCallback(() => {
    setValue((state) => !state);
  }, []);

  return [value, toggle];
};

export default useToggle;
