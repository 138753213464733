import { Agent } from '@agentsonly/models';
import {
  addDays,
  addMinutes,
  format,
  isWithinInterval,
  startOfWeek,
  setDayOfYear,
  set,
  endOfWeek,
} from 'date-fns';
import { groupBy } from 'lodash';
import { Schedule } from 'shared/models/Schedule';
import { AgentShift } from 'shared/models/AgentShift';
import { ClientShift } from 'shared/models/ClientShift';
import { toUTC } from 'shared/utils/time';
import { ShiftStatus, ShiftStatusEnum } from 'shared/ui/components/Schedule';
import { SCHEDULE_STEP, COUNTS_OF_DAY_PER_30MINS } from './consts';
import { getYearWeekFormat, parseYearWeekFormatToDate } from './date';

export interface ClientShiftSlot<TShift extends ClientShift = ClientShift> {
  date: Date;
  shift?: TShift;
  status?: ShiftStatus;
  customTimeFormat?: string;
}

export interface AgentShiftSlot<TShift extends AgentShift = AgentShift> {
  date: Date;
  shift?: TShift;
  status?: ShiftStatus;
  customTimeFormat?: string;
}

export const parseTime = (time: string) => [
  Number(time.slice(0, 2)),
  Number(time.slice(2)),
];

export const getYearWeekFormatFromShiftId = (shiftId: string): string => {
  const date = toUTC(new Date(Number(shiftId)));
  return getYearWeekFormat(date);
};

export const getScheduleWeekStartDay = (yearWeekFormat: string) =>
  startOfWeek(
    yearWeekFormat ? parseYearWeekFormatToDate(yearWeekFormat) : new Date(),
  );

export const getScheduleWeekEndDay = (yearWeekFormat: string) =>
  endOfWeek(
    yearWeekFormat ? parseYearWeekFormatToDate(yearWeekFormat) : new Date(),
  );

export const changeSchedule = (
  yearWeekFormat: string,
  weekChangeFn: (date: number | Date, amount: number) => Date,
): string =>
  getYearWeekFormat(weekChangeFn(getScheduleWeekStartDay(yearWeekFormat), 1));

export const sumClientShifts = (
  propFn: (shift: ClientShift) => number,
  shifts: ClientShift[],
) => shifts.reduce((sum: number, x) => sum + propFn(x), 0);

export const sumAgentShifts = (
  propFn: (shift: AgentShift) => number,
  shifts: AgentShift[],
) => shifts.reduce((sum: number, x) => sum + propFn(x), 0);

const getDaysMinsSlots = (weekStartDate: Date) => {
  // Get 48 Slot from the day start of the year
  const firstDayOfTheYear = setDayOfYear(weekStartDate, 1);
  const mins = Array.from({ length: COUNTS_OF_DAY_PER_30MINS }).map((_, i) => {
    return addMinutes(firstDayOfTheYear, SCHEDULE_STEP * i);
  });
  // Create 7 days array from the week start date
  const days = Array.from({ length: 7 }).map((_, i) =>
    addDays(weekStartDate, i),
  );
  return { days, mins };
};

export const DEFAULT_RESERVE_LIMIT = 1;

export const getClientSlots = <TShift extends ClientShift = ClientShift>(
  start: Date,
  shifts: TShift[],
  getStatus: (shift: ClientShift, now: number) => ShiftStatus,
) => {
  const now = Date.now();

  const daysMinsSlots = getDaysMinsSlots(start);

  return daysMinsSlots.days.reduce(
    (slots: ClientShiftSlot<TShift>[], shiftStartDate) => {
      const minutesSlots = daysMinsSlots.mins.map((time) => {
        const shiftDate = set(shiftStartDate, {
          hours: time.getHours(),
          minutes: time.getMinutes(),
        });

        // If DST begin date, the date has 23 hours
        // It has 1 hour gap, then fill with the not available slot.
        const convertToHourMinuteFromDate = format(shiftDate, 'H:m');
        const baseHourMinute = format(time, 'H:m');
        if (convertToHourMinuteFromDate !== baseHourMinute) {
          return {
            date: shiftDate,
            shift: undefined,
            status: ShiftStatusEnum.NOT_AVAILABLE,
            customTimeFormat: `${time.getHours().toString()}:${time
              .getMinutes()
              .toString()
              .padStart(2, '0')}`,
          };
        }

        const nextShiftDate = addMinutes(shiftDate, SCHEDULE_STEP - 1);
        const shift = shifts.find((x) =>
          isWithinInterval(x.startTime.toMillis(), {
            start: shiftDate,
            end: nextShiftDate,
          }),
        );

        const validReserveLimit = Object.entries(shift?.reserveLimit ?? {})
          .filter(([, count]) => count >= DEFAULT_RESERVE_LIMIT)
          .reduce((prev, [iso, count]) => ({ ...prev, [iso]: count }), {});

        return {
          date: shiftDate,
          shift: shift
            ? {
                ...shift,
                reserveLimit: validReserveLimit,
              }
            : undefined,
          status: shift && getStatus(shift, now),
        };
      });

      return [...slots, ...minutesSlots];
    },
    [],
  );
};

export const getAgentSlots = <TShift extends AgentShift = AgentShift>(
  start: Date,
  shifts: TShift[],
  schedule: Schedule | undefined,
  agent: Agent | undefined,
  getStatus: (shift: AgentShift, now: number) => ShiftStatus,
) => {
  const now = Date.now();
  const preferentialAgents = schedule?.preferential?.agents ?? [];

  const daysMinsSlots = getDaysMinsSlots(start);

  return daysMinsSlots.days.reduce(
    (slots: AgentShiftSlot<TShift>[], shiftStartDate) => {
      const minutesSlots = daysMinsSlots.mins.map((time) => {
        const shiftDate = set(shiftStartDate, {
          hours: time.getHours(),
          minutes: time.getMinutes(),
        });

        // If DST begin date, the date has 23 hours
        // It has 1 hour gap, then fill with the not available slot.
        const convertToHourMinuteFromDate = format(shiftDate, 'H:m');
        const baseHourMinute = format(time, 'H:m');
        if (convertToHourMinuteFromDate !== baseHourMinute) {
          return {
            date: shiftDate,
            shift: undefined,
            status: ShiftStatusEnum.NOT_AVAILABLE,
            hasTimeGap: true,
            customTimeFormat: `${time
              .getHours()
              .toString()
              .padStart(2, '0')}:${time
              .getMinutes()
              .toString()
              .padStart(2, '0')} AM`,
          };
        }

        const nextShiftDate = addMinutes(shiftDate, SCHEDULE_STEP - 1);
        const shift = shifts.find((x) =>
          isWithinInterval(x.start, {
            start: shiftDate,
            end: nextShiftDate,
          }),
        );

        let shiftPreferentialAgents: string[] = [];
        if (shift) {
          shiftPreferentialAgents =
            agent && preferentialAgents.includes(agent.id) ? [agent.id] : [];
        }

        return {
          date: shiftDate,
          shift: shift
            ? { ...shift, preferentialAgents: shiftPreferentialAgents }
            : undefined,
          status: shift && getStatus(shift, now),
        };
      });

      return [...slots, ...minutesSlots];
    },
    [],
  );
};

type PlatformSlot = ClientShiftSlot | AgentShiftSlot;

/**
 * Groups an array of slots based on the provided time format.
 *
 * @template TSlot - The type of the slot object.
 * @param {TSlot[]} data - The array of slots to be grouped.
 * @param {string} timeFormat - The time format used to group the slots.
 * @returns {Record<string, TSlot[]>} - An object where the keys are the hour values and the values are arrays of slots.
 */
export const groupShiftsByHour = <TSlot extends PlatformSlot>(
  data: TSlot[],
  timeFormat: string,
): Record<string, TSlot[]> =>
  groupBy(data, (item) => {
    // Handle custom time format if the timezone was changed
    // CAUTION: The custom time format must aligned with the provided timeFormat
    if (item?.customTimeFormat) {
      return item.customTimeFormat;
    }
    return format(item.date, timeFormat);
  });
