import { ProjectStatus } from 'shared/models/AgentsProject';

export const PROJECT_STATUS_LABELS = {
  [ProjectStatus.Approved]: 'Approved',
  [ProjectStatus.Pending]: 'Pending',
  [ProjectStatus.Training]: 'Training',
  [ProjectStatus.Removed]: 'Removed',
};

const PROJECT_STATUS_ORDER = [
  ProjectStatus.Pending,
  ProjectStatus.Training,
  ProjectStatus.Removed,
  ProjectStatus.Approved,
];

export const PROJECT_STATUS_FILTER_ALL = 'all';

export const PROJECT_STATUS_FILTER: { key: string; value: string }[] = (() => {
  const projectStatus = PROJECT_STATUS_ORDER.map((s) => ({
    key: s.toString(),
    value: PROJECT_STATUS_LABELS[s],
  }));

  const allFilter = { key: PROJECT_STATUS_FILTER_ALL, value: 'All' };

  return [allFilter, ...projectStatus];
})();

export const TABLE_SIZE_KEY = 'client-table-size';
export const DEFAULT_PAGE_SIZE = 10;
