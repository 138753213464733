const Primary = {
  main: '#6359E9',
  medium: '#00C569',
  light: '#00D0A3',
  dark: '#6359E9',
  client: '#3A1CAC',
  50: '#F9F5FF',
  500: '#6938EF',
  600: '#5028CD',
} as const;

export default Primary;
